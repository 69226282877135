import { Trans } from 'react-i18next';

const ALL_EQUALS = 'ALL_EQUALS';
const AREA_EQUALS = 'AREA_EQUALS';
const ALL_DIFFERENT = 'ALL_DIFFERENT';
const LOCATION_EQUALS = 'LOCATION_EQUALS';

const values = {
  ALL_EQUALS,
  AREA_EQUALS,
  ALL_DIFFERENT,
  LOCATION_EQUALS
};

const collection = [
  { value: ALL_EQUALS, label: <Trans i18nKey='enums.work_lock_types.ALL_EQUALS'/>, color: 'light' },
  { value: AREA_EQUALS, label: <Trans i18nKey='enums.work_lock_types.AREA_EQUALS'/>, color: 'light' },
  { value: LOCATION_EQUALS, label: <Trans i18nKey='enums.work_lock_types.LOCATION_EQUALS'/>, color: 'light' },
  { value: ALL_DIFFERENT, label: <Trans i18nKey='enums.work_lock_types.ALL_DIFFERENT'/>, color: 'light' },
];

const WorkLockTypeEnum = {
  values,
  collection,
};

export default WorkLockTypeEnum;
