import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateDisplay from '../../../../../components/UI/Displays/DateDisplay';
import DelayDisplay from '../../../../../components/UI/Displays/DelayDisplay';
import WorkDetailsDrawer from './WorkDetailsDrawer';
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardContentBetween } from '../../../../../components/UI/Card/CardContentBetween';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import EditButton from '../../../../../components/UI/Buttons/EditButton';
import { List } from '../../../../../components/UI/ItemList/List';
import { ListItem } from '../../../../../components/UI/ItemList/ListItem';
import { TagList } from '../../../../../components/UI/Tags/TagList';
import MarketDisplay from '../../../../../components/UI/Displays/MarketDisplay';

const WorkDetails = () => {

  const { work, dispatchAction } = useContext(WorkContext);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = res => {
    setIsOpen(false)
    dispatchAction({
      type: workActionTypes.UPDATE_DETAILS,
      payload: res
    })
  }

  return (
    <>
      <Card>
        <CardBody>
          <CardContentBetween>
            <CardLabel>{t('details')}</CardLabel>
            <EditButton onClick={() => setIsOpen(true)}/>
          </CardContentBetween>
          <List>
            <ListItem label={t('Tags')}>
              <TagList tags={work.tags}/>
            </ListItem>
            <ListItem label={t('market')}>
              <MarketDisplay market={work.company?.market} />
            </ListItem>
            <ListItem label={t('works.forecast_date')}>
              <DateDisplay value={work.forecast_date}/>
            </ListItem>
            <ListItem label={t('works.delivery_date')}>
              <DateDisplay value={work.delivery_date}/>
            </ListItem>
            <ListItem label={t('works.delivery_status')}>
              <DelayDisplay value={work?.delivery_status}/>
            </ListItem>
            <ListItem label={t('works.has_new_guide')}>
              {work?.has_new_guide ? t('yes') : t('no')}
            </ListItem>
            <ListItem label={t('note')}>
              {work?.note}
            </ListItem>
            <ListItem label={t('works.lock_type')}>
              {work?.lock_type ? t(`enums.work_lock_types.${work?.lock_type}`): '---'}
            </ListItem>
            <ListItem label={t('works.lock_notes')}>
              {work?.lock_notes ?? '---'}
            </ListItem>
          </List>
        </CardBody>
      </Card>

      <WorkDetailsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onUpdate={handleUpdate}
      />
    </>
  )

}

export default WorkDetails
