import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import defaultFlag from '../../../assets/images/flags/undefined.svg';


const MarketDisplay = ({ market }) => {

  return market && market.value ?
    <div className='d-flex align-items-center justify-content-start gap-2'>
      <ReactCountryFlag
        countryCode={market.value}
        defaultValue={'undefined'}
        svg
        style={{ width: '2em', height: '2em' }}
        title={market.label}
      />
      {market.label}
    </div>
    : <img
      src={defaultFlag}
      style={{
        display: 'inline-block',
        width: '2em',
        height: '2em',
        verticalAlign: 'middle'
      }}
    />

}

export default MarketDisplay
